<template>
  <div class="create-okrnew m-auto">
    <!-- Create OKR Popup -->
    <!-- <b-button v-b-modal.create-okr>Launch demo modal</b-button> -->
    <!-- Previous OKR Popup-->
    <b-modal id="previous-updateokr" hide-header hide-footer centered>
      <div class="okr-body text-center">
        <h3 class="mb-1">Update Previous OKR</h3>
        <p class="my-1">You can't set new goals until you update the ones you're already working on (or) you can also push them to the next quarter ({{this.getNextQuarterYear()}})</p>
        <div class="d-flex align-items-center justify-content-center previous-btn mt-2">
          <b-button variant="outline-secondary" @click="$bvModal.hide('previous-updateokr')" pill>Cancel</b-button>
          <b-button variant="primary" @click="$bvModal.hide('previous-updateokr')" pill>Update New</b-button>
        </div>
      </div>
    </b-modal>
     <!-- End Previous OKR Popup-->
    <!-- End Create Popup -->
    <!--cmt-active-->
    <form-wizard ref="createForm" color="#38B22D" :title="null" :subtitle="null" class="mb-3 okr-steps " @on-complete="formSubmitted"
      finish-button-text="Submit" @on-change="handleTabChange" >
      <!-- <p @click="$bvModal.show('previous-okr')">View Prev. OKRs</p> -->
      <tab-content title="Update OKRs" id="step-1" class="px-0">
        <h6 class="mb-0 wizard-head">Update Previous OKR</h6>
        <b-row class="px-2 py-1 info-notes m-0" v-if="!currentPublishedOKRs || currentPublishedOKRs.length == 0">
          <b-col cols="auto" class="px-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <g clip-path="url(#clip0_7756_5613)">
                <path
                  d="M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 7.20979 15.0388 5.4929 13.773 4.22703C12.5071 2.96116 10.7902 2.25 9 2.25C7.20979 2.25 5.4929 2.96116 4.22703 4.22703C2.96116 5.4929 2.25 7.20979 2.25 9Z"
                  stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 6.75H9.0075" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.25 9H9V12H9.75" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_7756_5613">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </b-col>
          <b-col class="pl-75" >
            <p class="mb-0">You can't set new goals until you update the ones you're already working on, or
              you can also push them to the next quarter({{this.getNextQuarterYear()}}).</p>
          </b-col>
        </b-row>
        <!-- Old okr update -->
        <div v-if="currentPublishedOKRs || currentPublishedOKRs.length != 0">
          <h1 class="mt-1 mb-1 pt-25" v-if="quarterType|| quarterType == 'previous' ">{{ getLastQuarterTitle() }}</h1>
          <h1 class="mt-1 mb-1 pt-25" v-else>{{  okr_group_title}}</h1>
        </div>        
        <div class="okr-list okr-status" v-if="currentPublishedOKRs.length">
          <ul class="m-0 p-0">
            <li class="list-unstyled mb-2" v-for="objective in currentPublishedOKRs">
              <b-card class="shadow-none">
                <b-badge class="ml-auto" :variant="getStatusVariant(objective.status)"> {{ objective.status }}</b-badge>
                <b-row v-b-toggle="'KeyResultsView-' + objective.hashid" class="first-previewstep">
                  <b-col sm="10" md="10" class="d-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="click-icon">
                      <path d="M15.6649 12.8547L10.266 18.7558C10.1915 18.8372 10.1108 18.8983 10.0239 18.939C9.93706 18.9797 9.84397 19 9.74468 19C9.5461 19 9.37234 18.9254 9.2234 18.7762C9.07447 18.6269 9 18.4302 9 18.186L9 5.81395C9 5.56977 9.07447 5.37306 9.2234 5.22384C9.37234 5.07461 9.5461 5 9.74468 5C9.79433 5 9.96808 5.0814 10.266 5.24419L15.6649 11.1453C15.789 11.281 15.8759 11.4167 15.9255 11.5523C15.9752 11.688 16 11.8372 16 12C16 12.1628 15.9752 12.312 15.9255 12.4477C15.8759 12.5833 15.789 12.719 15.6649 12.8547Z" fill="#667085"/>
                    </svg>
                    <h5 class="main-header my-1 ml-25">{{ objective.title }}
                      <svg v-if="objective.updated_at != null" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" v-b-tooltip.hover :title="'Updated '+objective.updated_at">
                        <g clip-path="url(#clip0_7712_51573)">
                          <path d="M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z" fill="#0D6EFD" />
                          <path d="M7.5 10.0007L9.16667 11.6673L12.5 8.33398" stroke="white" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath id="clip0_7712_51573">
                          <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                     </h5>
                  </b-col>
                  <b-col class="text-right" sm="2" md="2">
                    <!-- <radial-progress-bar :diameter="70"
                       :completed-steps="Number(objective.overall_status.percentage)"
                       :total-steps="100"></radial-progress-bar> -->
                       <div class="circle_percent" :percent="Number(objective.overall_status.percentage)">
                        <div class="circle_inner">
                          <div class="round_per"></div>
                        </div>
                      </div>
                    <!-- <div class="progress mx-auto" :data-value="objective.overall_status.percentage">
                      <div
                        class="progress-value lined-progress w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                        <div class="percentage mb-0">{{ objective.overall_status.percentage }}%</div>
                      </div>
                    </div> -->
                  </b-col>
                </b-row>
                <!-- <b-button variant="outline-primary" pill class="new-button w-auto mt-1" @click="AddtoCurrentOKR(objective)">
                  Include in New OKR
                </b-button> -->
                <!-- <div class="expand-div" v-b-toggle="'KeyResultsView-' + objective.hashid">
                  <div class="d-flex align-items-center justify-content-center expand-old">
                    <feather-icon icon="PlusIcon" class="before-expand" />
                    <feather-icon icon="MinusIcon" class="after-expand" />
                  </div>
                </div> -->
              
              <b-collapse :id="'KeyResultsView-' + objective.hashid">
                
                <div>
                  <b-form>
                    <ul class="m-0 p-0 tree h-100 nested-okr overflow-visible">
                      <li class="list-unstyled nested-table">
                        <ul class="m-0 pl-0 sub-tree">
                          <li class="list-unstyled mt-1" >
                            <div class="shadow-none mb-0 rounded-top">
                              <b-row
                                class="overall-progress main-header px-2 ml-50 py-1 d-flex align-items-center">
                                <b-col sm="12" md="8" class="pl-0">
                                  <div class="title-sub main-sub">Overall Progress</div>
                                </b-col>
                                <b-col sm="12" md="4" class="pr-0 text-md-right status-drop">
                                  <!-- <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="objective.status"
                                    :options="statusOptions" class="w-100"
                                    placeholder="In Progress" /> -->
                                    <b-dropdown variant="outline-secondary" :text="objective.status" placeholder="Status" class="custom-multiselect">
                                      <b-dropdown-item @click="objective.status = status"
                                        v-for="status in statusOptions">{{ status }}</b-dropdown-item>
                                    </b-dropdown>
                                </b-col>
                              </b-row>
                              <b-row v-for="(key_result,k) in objective.key_results"
                                class="overall-progress main-header px-2 ml-50 py-1 d-flex align-items-center">
                                <b-col sm="12" md="8" class="pl-0" >
                                  <div class="title-sub">{{key_result.result_description}}</div>
                                </b-col>
                                <b-col sm="12" md="4" class="pr-0 d-flex align-items-center justify-content-md-end">
                                  <div class="">
                                    <!-- <h6 v-if="key_result.metric_type != 'Boolean'">Progress</h6>
                                    <h6 v-else>Is Completed</h6> -->
                                    <div class="d-flex align-items-center report-value" v-if="key_result.metric_type != 'Boolean'">
                                      <input type="number" v-model="key_result.progress" class="enabled" >
                                      <span class="mx-50">/</span>
                                      <input type="number" v-model="key_result.goal" class="disabled border-0">
                                    </div>
                                    <div class="d-flex align-items-center report-value justify-content-md-end yesno-switch" v-else >
                                      <b-button :class="(key_result.state && key_result.state == 'Yes')?'yes-btn selected':'yes-btn'"  @click="key_result.state='Yes'" variant="outline-secondary">Yes</b-button>
                                      <b-button :class="(!key_result.state || key_result.state == 'No')?'no-btn selected':'no-btn'"   @click="key_result.state='No'" variant="outline-secondary">No</b-button>
                                    </div>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div class="d-flex align-items-center justify-content-between pt-1 shadow-none update-btn rounded-0 after-update border-top ml-0">
                      <div class="include-newokr">
                        <b-form-checkbox
                            v-model="objective.moveToNext"
                            value="yes"
                            unchecked-value="no"
                          >
                          Move to Next Cycle
                          </b-form-checkbox>
                          <!-- <b-button variant="outline-primary" pill class="new-button w-auto" @click="AddtoCurrentOKR(objective)">
                            Include in New OKR
                          </b-button>  -->
                      </div>
                      <div class="d-flex align-items-center">
                        <b-button variant="outline-secondary" class="w-auto" @click="getMyOkRs" pill>Cancel</b-button>
                        <b-button variant="primary" class="w-auto ml-1"  @click="updateObjectiveProgress(objective)" pill>Update </b-button>
                      </div>
                    </div>
                  </b-form>
                </div>
              </b-collapse>
            </b-card>
            </li>
          </ul>
        </div>
        <div class="text-center my-2 empty-data" v-else>
          <b-img :src="require('@/assets/images/icons/okr/EmptyReview.svg')" class="mx-auto light-theme" style="background-color: transparent" />
          <b-img :src="require('@/assets/images/icons/okr/emptyreviewdark.svg')" class="mx-auto dark-theme" style="background-color: transparent" />
          <h4> No OKR to Review </h4>
          <p class="d-block mt-75 mb-1 pb-25">Create Your First OKR To Get Started.</p>
          <b-button variant="primary" pill slot="next" @click="$refs.createForm.nextTab()"> Add New OKR </b-button>
        </div>
        <!-- End Old okr update -->
      </tab-content>

      <!-- Second Step -->
      <tab-content title="Add New" id="step-2">
        <h6 class="mb-0 wizard-head">Create New OKR - {{this.getNextQuarterYear()}}</h6>
        <!-- Create -->
        <AddUpdateObjectiveForm ref="addForm" :parent-type-options="parentTypeOptions"
          @objective-submitted="submitChanged" :type="type" @refresh-division="getTeamOptions()"
          :team-options="teamOptions" :employee-options="employeeOptions" />
        <div class="d-flex align-items-center justify-content-end  my-1 pb-50">
          <!-- <div class="add-result mr-1" @click="$refs.addForm.addKeyResult()">
            <a class="font-weight-bolder" variant="outline-secondary">
              <feather-icon icon="PlusIcon" class="font-weight-bolder" /> <span class="hover-btn">Add Key
                Result</span></a>
          </div> -->
          <b-button :disabled="is_loading.submit" variant="primary" pill class="new-button w-auto"
            @click="$refs.addForm.addObjectiveToDB()">
            Create
          </b-button>
        </div>
        <!-- End Create -->
        <h1 class="mt-2 mb-1" v-if="nonPublishedOKRs.length" >{{ okr_group_title }}</h1>
        <!-- <h1 class="mt-2 mb-1 pt-25" v-else >No OKRs awaiting review.</h1> -->
        <div class="okr-list individual-okr okr-status">
          <ul class="m-0 p-0">
            <li class="list-unstyled pt-50" v-for="objective in nonPublishedOKRs">
              <div v-if="!objective.isInEditState">
                <b-card class="shadow-none">
                  <b-row>
                    <b-col sm="12">
                      <div class="d-flex align-items-center justify-content-between w-100">
                        <b-badge :variant="getStatusVariant(objective.status)"> {{ objective.status }}</b-badge>
                        <div class="okr-actions">
                          <div class="d-flex align-items-center">
                            <feather-icon icon="EditIcon" size="13" stroke="#6E6B7B" class="cursor-pointer"
                              @click="objective.isInEditState = true" />
                            <div class="vl mx-75"></div>
                            <feather-icon icon="TrashIcon" size="13" stroke="#FF471F" class="cursor-pointer"
                              @click="confirmDeleteRecord(objective)" />
                          </div>
                        </div>
                      </div>
                      <b-row class="d-flex align-items-center justify-content-between w-100 mt-50 mx-0 after-expand" v-b-toggle="'KeyResultsView-' + objective.hashid">
                        <b-col class="d-flex align-items-center pl-md-0" sm="10" md="10">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 7 14" fill="none" class="click-icon">
                            <path
                              d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z"
                              fill="#6E6B7B" />
                          </svg>
                          <h4 class="main-header my-1 w-100 pl-50">{{ objective.title }}</h4>
                        </b-col>
                        <b-col class="text-right pr-md-0" sm="2" md="2">
                          <!-- <div class="d-flex align-items-center sub-progress">
                            <b-progress :value="objective.overall_status.percentage" max="100" class="flat-progress"></b-progress>
                            <span class="ml-50">{{objective.overall_status.percentage}}%</span>
                          </div> -->
                          <div class="circle_percent" :percent="Number(objective.overall_status.percentage)">
                            <div class="circle_inner">
                              <div class="round_per"></div>
                            </div>
                          </div>
                      </b-col>
                    </b-row>
                    </b-col>
                  </b-row>

                  <!-- <div class="expand-div" v-b-toggle="'KeyResultsView-' + objective.hashid">
                    <div class="d-flex align-items-center justify-content-center expand-old">
                      <feather-icon icon="PlusIcon" class="before-expand" />
                      <feather-icon icon="MinusIcon" class="after-expand" />
                    </div>
                  </div> -->
                <b-collapse :id="'KeyResultsView-' + objective.hashid">
                  <ul class="m-0 p-0 tree h-100 nested-okr">
                    <li class="list-unstyled nested-table">
                      <ul class="m-0 sub-tree pl-50 border-top">
                        <li class="list-unstyled border-0 my-25" v-for="key_result in objective.key_results">
                          <div class="shadow-none rounded">
                            <b-row class="overall-progress main-header ml-1 my-1 mr-0 pb-1">
                              <b-col sm="8" md="8" class="pl-50">
                                <label>{{ key_result.result_description }}</label>
                                  <!-- <div class="final-value">
                                    <h6 class="mb-25">Progress</h6>
                                    <p class="mb-0" v-if="key_result.metric_type == 'Boolean'">{{ (key_result.state &&
                                      key_result.state == 'Yes') ? 'Completed' : 'Incomplete' }}</p>
                                    <p class="mb-0" v-if="key_result.metric_type == 'Number'">{{ key_result.progress + " / "+key_result.goal}}</p>
                                    <p class="mb-0" v-if="key_result.metric_type == 'Percentage'">{{ key_result.progress +
                                      " / " + key_result.goal + "%" }}</p>
                                  </div> -->
                              </b-col>
                              <b-col sm="4" md="4" class="justify-content-end d-flex pr-md-0">
                                <div class="input-value">
                                  <p class="mb-0" v-if="key_result.metric_type == 'Boolean'">{{ (key_result.state &&
                                    key_result.state == 'Yes') ? 'Completed' : 'Incomplete' }}</p>
                                  <p class="mb-0" v-if="key_result.metric_type == 'Number'">{{ key_result.progress + " / "+key_result.goal}}</p>
                                  <p class="mb-0" v-if="key_result.metric_type == 'Percentage'">{{ key_result.progress +
                                    " / " + key_result.goal + "%" }}</p>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </b-collapse>
              </b-card>
              </div>
              <div v-else class="my-1">
                <AddUpdateObjectiveForm :ref="'editForm-' + objective.hashid" :parent-type-options="parentTypeOptions"
                   @objective-submitted="submitChanged" :type="type"
                  @refresh-division="getTeamOptions()" :team-options="teamOptions"
                  :employee-options="employeeOptions" :item-data="objective" />

                <div class="d-flex align-items-center justify-content-end mt-1">
                  <!-- <div class="add-result mr-1" @click="$refs['editForm-' + objective.hashid][0].addKeyResult()">
                    <a class="font-weight-bolder" variant="outline-secondary">
                      <feather-icon icon="PlusIcon" class="font-weight-bolder" /> <span class="hover-btn">Add Key
                        Result</span></a>
                  </div> -->
                  <b-button variant="outline-secondary" pill class="cancel-button w-auto" @click="getMyOkRs()">
                    Cancel
                  </b-button>
                  <b-button variant="primary" pill class="ml-1 w-auto"
                    @click="$refs['editForm-' + objective.hashid][0].addObjectiveToDB('update')">
                    Save
                  </b-button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- End Edit -->
      </tab-content>

      <!-- Step3-->
      <tab-content title="Review" class="final-step" id="step-3">
        <h6 class="mb-0 wizard-head">Review</h6>
        <b-row class="px-2 py-1 info-notes m-0" v-if="nonPublishedOKRs.length">
          <b-col cols="auto" class="px-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <g clip-path="url(#clip0_7756_5613)">
                <path
                  d="M2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C9.88642 15.75 10.7642 15.5754 11.5831 15.2362C12.4021 14.897 13.1462 14.3998 13.773 13.773C14.3998 13.1462 14.897 12.4021 15.2362 11.5831C15.5754 10.7642 15.75 9.88642 15.75 9C15.75 7.20979 15.0388 5.4929 13.773 4.22703C12.5071 2.96116 10.7902 2.25 9 2.25C7.20979 2.25 5.4929 2.96116 4.22703 4.22703C2.96116 5.4929 2.25 7.20979 2.25 9Z"
                  stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 6.75H9.0075" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8.25 9H9V12H9.75" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_7756_5613">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </b-col>
          <b-col class="pl-75" >
            <p class="mb-0 review-note">Your reporting manager will review your OKR and provide feedback or suggestions for improvement. Once approved, a blue tick (<b-img :src="require('@/assets/images/icons/okr/tick.svg')" class="d-inline" />) will appear next to the OKR title to indicate its approval status.</p>
          </b-col>
        </b-row>
        <!-- Old okr update -->
        <div class="d-flex align-items-center justify-content-between after-active">
          <h1 class="mt-2 mb-1" v-if="nonPublishedOKRs.length" >{{ okr_group_title }}</h1>
          <div class="right-filter d-flex custom-dropdown" v-if="activeTab == 'step-3'">
              <b-button v-if="nonPublishedOKRs.length" :variant="(commentsOpened == false) ? 'white' :'primary'" pill class="cmts-history p-0" @click="commentsOpened = !commentsOpened">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g clip-path="url(#clip0_10714_37718)">
                      <path d="M6 6.75H12" stroke="#667085" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6 9.75H10.5" stroke="#667085" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.75 13.5H4.5C3.90326 13.5 3.33097 13.2629 2.90901 12.841C2.48705 12.419 2.25 11.8467 2.25 11.25V5.25C2.25 4.65326 2.48705 4.08097 2.90901 3.65901C3.33097 3.23705 3.90326 3 4.5 3H13.5C14.0967 3 14.669 3.23705 15.091 3.65901C15.5129 4.08097 15.75 4.65326 15.75 5.25V11.25C15.75 11.8467 15.5129 12.419 15.091 12.841C14.669 13.2629 14.0967 13.5 13.5 13.5H11.25L9 15.75L6.75 13.5Z" stroke="#667085" stroke-width="1.35" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_10714_37718">
                        <rect width="18" height="18" fill="#667085"/>
                      </clipPath>
                    </defs>
                  </svg>
                </b-button>
                <b-button variant="outline-secondary" pill v-if="nonPublishedOKRs.length && GetHistories().length" v-b-modal.edithistory-pop
                  class="history-btn p-0 d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_10712_37110)">
                      <path d="M8 5.33203V7.9987L9.33333 9.33203" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M2.03516 7.33338C2.18455 5.86675 2.86867 4.50645 3.95698 3.51202C5.04529 2.51759 6.46162 1.95864 7.93574 1.9418C9.40986 1.92496 10.8386 2.45142 11.9493 3.42074C13.0601 4.39005 13.7751 5.73436 13.9579 7.1972C14.1408 8.66003 13.7787 10.139 12.9407 11.3519C12.1028 12.5648 10.8476 13.4267 9.41468 13.7733C7.98177 14.1198 6.47143 13.9267 5.17182 13.2308C3.87221 12.5348 2.8743 11.3848 2.36849 10M2.03516 13.3334V10H5.36849" stroke="#6E6B7B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_10712_37110">
                        <rect width="16" height="16" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </b-button>
          </div>
        </div>
        <div class="okr-list okr-status custom-progress" v-if="nonPublishedOKRs.length">
          <ul class="m-0 p-0">
            <li class="list-unstyled mt-75" v-for="objective in nonPublishedOKRs">
              <div v-if="!objective.isInEditState" :class="(commentsOpened == false) ? 'before-viewcmd' :'after-viewcmd'">
                <b-card class="shadow-none mb-0 left-data">
                  <b-row v-b-toggle="'KeyResultsView-' + objective.hashid">
                    <b-col sm="12" md="8" class="d-flex align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 7 14" fill="none" class="click-icon">
                        <path
                          d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z"
                          fill="#6E6B7B" />
                      </svg>
                      <h4 class="mb-0 pb-0 ml-50">{{ objective.title }}</h4>
                    </b-col>
                    <b-col sm="12" md="4" class="d-flex align-items-center justify-content-end">
                      <b-badge :variant="getStatusVariant(objective.status)"> {{ objective.status }}</b-badge>
                      <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0"
                        no-caret>
                        <template v-slot:button-content>
                          <feather-icon icon="MoreVerticalIcon" size="18"
                            class="text-body align-middle ml-50 text-muted" v-b-tooltip.hover.v-default
                            title="More Options" />
                        </template>
                        <b-dropdown-item class="btn-sm p-0 text-muted"  @click="objective.isInEditState = true">
                          <feather-icon icon="EditIcon" size="16" class="mr-50"
                            stroke="#6E6B7B" />
                          <span class="text-secondary">Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item class="btn-sm p-0" @click="confirmDeleteRecord(objective)">
                          <feather-icon icon="TrashIcon" size="16" class="mr-50" stroke="#6E6B7B" />
                          <span class="text-secondary" >Delete</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                  <b-collapse :id="'KeyResultsView-' + objective.hashid"  @shown="commentTarget = objective.hashid+'-comment'" @hidden="commentTarget=null;" >
                  <ul class="m-0 p-0 tree h-100 nested-okr final-stepss">
                    <li class="list-unstyled nested-table mt-75">
                      <ul class="m-0 sub-tree w-100">
                        <li class="list-unstyled border-0 p-0" v-for="key_result in objective.key_results">
                          <div class="shadow-none py-50 rounded">
                            <b-row>
                              <b-col sm="8" md="8" class="pl-50">
                                <label class="mb-0">{{ key_result.result_description }}</label>
                              </b-col>
                              <b-col sm="4" md="4" class="justify-content-md-end d-flex">
                                <div class="input-value">
                                  <p class="mb-0" v-if="key_result.metric_type == 'Boolean'">{{ (key_result.state &&
                                    key_result.state == 'Yes') ? 'Completed' : 'Incomplete' }}</p>
                                  <p class="mb-0" v-if="key_result.metric_type == 'Number'">{{ key_result.progress + " / "+key_result.goal}}</p>
                                  <p class="mb-0" v-if="key_result.metric_type == 'Percentage'">{{ key_result.progress +
                                    " / " + key_result.goal + "%" }}</p>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </b-collapse>
                </b-card>
                <!-- Comment History -->
                <div class="cmt-main">
                  <div class="enter-cmt new-cmt" v-if="objective.comments_count == 0 && commentTarget == objective.hashid+'-comment'">
                    <b-form-group class="mb-0">
                      <b-form-input placeholder="Add Comment" @keyup.enter="addOrUpdateComment(objective.hashid,$event)"/>
                    </b-form-group>
                  </div>
                  <div v-if="objective.comments_count != 0 && commentsOpened" class="cmt-view" >
                    <ul class="m-0 p-0">
                      <li class="cmt-list list-unstyled" v-for="cmd in objective.comments" >
                        <div class="d-flex align-items-center justify-content-between">
                          <p class="mb-0"><span class="user-detail">{{(cmd.comment_owner_id == userData.hashid) ? 'You' : cmd.comment_owner_name}}</span> <span class="date">{{getDate(cmd.time)}}</span> <span class="time">{{getDateTime(cmd.time)}}</span></p>
                          <p class="m-0 completed-tick d-flex align-items-center">
                            <feather-icon class="cursor-pointer" icon="CheckIcon" v-if="cmd.mark_as_done == true"/>
                            <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0"
                              no-caret>
                              <template v-slot:button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16"
                                  class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default
                                  title="More Options" />
                              </template>
                              <b-dropdown-item v-if="cmd.comment_owner_id != userData.hashid && cmd.mark_as_done == false"  class="btn-sm p-0 border-0" @click="markAsResolved(objective.hashid,cmd,'resolved')">
                                <feather-icon icon="CheckIcon" size="13" class="mr-50" />
                                <span class="">Mark as resolved</span>
                              </b-dropdown-item>
                              <b-dropdown-item v-if="cmd.comment_owner_id != userData.hashid && cmd.mark_as_done == true"  class="btn-sm p-0 border-0" @click="markAsResolved(objective.hashid,cmd,'un-resolved')">
                                <feather-icon icon="XIcon" size="13" class="mr-50" />
                                <span class="">Mark as un-resolved</span>
                              </b-dropdown-item>
                              <b-dropdown-item  v-if="cmd.comment_owner_id == userData.hashid"  class="btn-sm p-0 border-0"  v-b-toggle="cmd.comment_hash_id">
                                <feather-icon icon="EditIcon" size="13" class="mr-50" />
                                <span class="">Edit</span>
                              </b-dropdown-item>
                              <b-dropdown-item v-if="cmd.comment_owner_id == userData.hashid" class="btn-sm p-0 border-0" @click="confirmDeleteCommand(objective.hashid,cmd)">
                                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                                <span class="">Delete</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </p>
                        </div>
                        
                        <p class="cmt-text m-0">{{cmd.comment}}</p>
                        <b-collapse :id="cmd.comment_hash_id">
                          <b-row>
                            <b-col md="12" class="mt-1">
                              <div class="enter-cmt" >
                                <b-form-group class="mb-0">
                                  <b-form-input placeholder="Add Comment" :value="cmd.comment" @keyup.enter="addOrUpdateComment(objective.hashid,$event,cmd.comment_hash_id)"/>
                                </b-form-group>
                              </div>
                            </b-col>
                          </b-row>
                        </b-collapse>

                      </li>
                      <b-row>
                        <b-col md="12" class="mt-1">
                          <div class="enter-cmt" >
                            <b-form-group class="mb-0">
                              <b-form-input  @keyup.enter="addOrUpdateComment(objective.hashid,$event)"/>
                            </b-form-group>
                          </div>
                        </b-col>
                      </b-row>
                    </ul>
                    <!-- <b-link class="cmt-expand">View Conversation</b-link> -->
                  </div>
              </div>
            <!-- End Comment History -->
              </div>
              <div v-else class="my-1">
                <AddUpdateObjectiveForm :ref="'editForm-' + objective.hashid" :parent-type-options="parentTypeOptions"
                   @objective-submitted="submitChanged" :type="type"
                  @refresh-division="fetchDivisionsList()" :team-options="teamOptions"
                  :employee-options="employeeOptions" :item-data="objective" />

                <div class="d-flex align-items-center justify-content-end mt-1">
                  <!-- <div class="add-result mr-1" @click="$refs['editForm-' + objective.hashid][0].addKeyResult()">
                    <a class="font-weight-bolder" variant="outline-secondary">
                      <feather-icon icon="PlusIcon" class="font-weight-bolder" /> <span class="hover-btn">Add Key Result</span></a>
                  </div> -->
                  <b-button variant="outline-secondary" pill class="cancel-button w-auto" @click="getMyOkRs()">
                    Cancel
                  </b-button>
                  <b-button variant="primary" pill class="ml-1 w-auto"
                    @click="$refs['editForm-' + objective.hashid][0].addObjectiveToDB('update')">
                    Save
                  </b-button>
                </div>
              </div>
            </li>
          </ul>

        </div>
        <!-- Publish Success Page -->
        <div class="text-center my-2 empty-data" v-else>
          <b-img :src="require('@/assets/images/icons/okr/success-okr.svg')" class="mx-auto light-theme"/>
          <b-img :src="require('@/assets/images/icons/okr/success-darkokr.svg')" class="mx-auto dark-theme"/>
          <h4> Your OKRs for this cycle are LIVE!</h4>
          <p class="d-block mt-75 mb-1 pb-25">Head over to My OKRs to see your OKRs.</p>
          <b-button variant="primary" :to="{
            name: 'my-okrs',
          }" pill>View My OKRs</b-button>
        </div>
        <!-- End Publish Success Page -->
        <!-- End Old okr update -->
      </tab-content>
      <!-- End Step3-->
      <b-modal id="edithistory-pop" size="md" title="Edit History" hide-footer class="custom-popmodal edit-history"
          right header-class="sticky-top">
          <template #modal-header="{ close }">
            <h5 class="header mb-0">Edit History</h5>
            <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="16" @click="closeHistory()" />
          </template>
          <div class="edit-content shadow-none rounded-bottom">
            <ul class="timeline-history m-0 p-0" v-for="history in GetHistories()">
              <li class="timeline__event"  v-if="history.old && history.new">
                <div class="timeline__event__user">
                  <b-img :src="history.user_avatar" class="mx-auto light-theme" />
                </div>
                <div class="timeline__event__content">
                  <div class="timeline__event__description">
                    <p class="mb-0">{{history.user_name}} {{history.action_type}} <span class="modify-text">“{{history.subject}}” from
                        <span class="value"><small>{{history.old}} </small></span> to <span class="value"><small>
                            {{history.new}}</small></span></span></p>
                    <div class="d-flex algin-items-center edit-date mt-25">
                      <span>{{history.time}}</span>
                    </div>
                  </div>
                </div>
              </li>
              <li class="timeline__event" v-else>
                <div class="timeline__event__user">
                  <b-img :src="history.user_avatar" class="mx-auto light-theme" />
                </div>
                <div class="timeline__event__content">
                  <div class="timeline__event__description">
                    <p class="mb-0">{{history.user_name}} {{history.action_type}} <span class="modify-text">“{{history.subject}}”</span></p>
                    <div class="d-flex algin-items-center edit-date mt-25">
                      <span>{{history.time}}</span>
                    </div>
                  </div>
                </div>
              </li>
              
            </ul>
          </div>
        </b-modal>
      <div v-if="activeTab == 'step-2'" class="prev-okr d-flex align-items-center gap-6 p-0 okr-submit">
        <!-- View okr option-->
        <b-button @click="$bvModal.show('previous-okr')"  variant="outline-secondary" pill class="">View Prev. OKRs</b-button>
        <!-- Submit Button -->
        <b-button v-if="nonPublishedOKRs && nonPublishedOKRs.length" @click="formSubmitted" slot="finish" class="submit-btn d-flex align-items-center" pill variant="primary"><span>{{ (type == 'Individual') ? 'Submit' : 'Publish' }}</span></b-button>
      </div>
    </form-wizard>
    <!-- Sidebar Previous OKR-->
    <PreviousOkr :getStatusVariant="getStatusVariant" :AddtoCurrentOKR="AddtoCurrentOKR"/>
  </div>
</template>
<script>
import $ from 'jquery';
import { $themeConfig } from '@themeConfig';
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import PreviousOkr from "@/views/new-okr/add/PreviousOkr.vue";
import AddUpdateObjectiveForm from "@/views/new-okr/add/AddUpdateObjectiveForm.vue";
import axios from "@axios";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  VBTooltip,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import Ripple from 'vue-ripple-directive'

import RadialProgressBar from 'vue-radial-progress'
import { ArrowRightIcon } from 'vue-feather-icons';

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    AddUpdateObjectiveForm,
    FormWizard,
    TabContent,
    PreviousOkr,
    BRow,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    vSelect,
    ToastificationContent,
    RadialProgressBar
  },
  mounted() {
    // $( ".wizard-card-footer" ).prepend( "ul.wizard-nav" );
    document.body.classList.add('revamp-okr');
    $(document).ready(function () {
      $(".click-expand").click(function () {
        $("ul.subtree").toggleClass("hidden");
      });
      $(".cmts-history").click(function () {
        $(".okr-status, .create-okrnew").toggleClass("active-cmt");
      });
    });
    var totalSteps = $(".steps li").length;

    $(".submit").on("click", function(){
      return false; 
    });

    $(".steps li:nth-of-type(1)").addClass("active");
    $(".myContainer .form-container:nth-of-type(1)").addClass("active");

    $(".form-container").on("click", ".next", function() { 
      $(".steps li").eq($(this).parents(".form-container").index() + 1).addClass("active"); 
      $(this).parents(".form-container").removeClass("active").next().addClass("active flipInX");   
    });

    $(".form-container").on("click", ".back", function() {  
      $(".steps li").eq($(this).parents(".form-container").index() - totalSteps).removeClass("active"); 
      $(this).parents(".form-container").removeClass("active flipInX").prev().addClass("active flipInY"); 
    });
    $(".steps li").on("click", function() {
      var stepVal = $(this).find("span").text();
      $(this).prevAll().addClass("active");
      $(this).addClass("active");
      $(this).nextAll().removeClass("active");
      $(".myContainer .form-container").removeClass("active flipInX");  
      $(".myContainer .form-container:nth-of-type("+ stepVal +")").addClass("active flipInX");     
    });

  },
  //remove body main class in other pages
  destroyed () {
    document.body.classList.remove('revamp-okr')
  },
  created() {
    this.getEmployees();
    this.getMyOkRs();
    // this.updateProgress();
    if(this.currentPublishedOKRs.length){
      this.$bvModal.show('previous-updateokr');
    }else{
      this.$refs.createForm.nextTab();
      this.activeTab = 'step-2';
    }
    if (this.$route.params.type) {
      let type = this.$route.params.type;
      if (type == "individual-okr") {
        this.type = 'Individual';
        this.okr_group_title = this.userData.name + "'s " + this.getCurrentQuarterYear()
      }
      else if (type == "team-okr") {
        this.type = 'Team';
        this.getTeamOptions();
        this.okr_group_title = "Team " + this.getCurrentQuarterYear();
      }
      else if (type == "organisation-okr") {
        this.type = 'Organisation';
        this.okr_group_title = "Organisation " + this.getCurrentQuarterYear();
      } else {
        this.type = 'Individual';
        this.okr_group_title = this.userData.name + "'s " + this.getCurrentQuarterYear();
      }
    }
  },
  watch: {
    // currentPublishedOKRs:function (val) {
    //   this.updateProgress();
    // },
    // nonPublishedOKRs:function (val) {
    //   this.updateProgress();
    // }
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      parentTypeOptions: ['Individual', 'Team', 'Organisation'],
      required,
      activeTab: 'step-1',
      is_loading: {
        submit: false,
      },
      currentPublishedOKRs: [],
      nonPublishedOKRs: [],
      type: null,
      userData: this.$cookies.get('userData'),
      okr_group_title: '',
      quarterType:'',


      statusOptions: ['Upcoming', 'Not Started Yet', 'In Progress', 'Completed', 'Cancelled', 'On Hold', 'Deferred'],
      teamOptions: [],
      employeeOptions: [],
      commentsOpened:false,
      commentTarget:'',
      newCommentText:''
    };
  },
  methods: {
    confirmDeleteCommand(objective_id,comment){
      this.$swal({
        title: "Are you sure?",
        text: "You are about to delete this comment '"+comment.comment+"'",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
            this.deleteComment(objective_id,comment);
        }
      });
    },
    deleteComment(objective_id,comment){
      axios.post("objective/deleteComment",{objective_id:objective_id,comment : comment})
        .then((res) => {
          if(res.data.success){
            this.getMyOkRs();
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment Deleted",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: "Selected Comment Deleted Successfully",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Deleted',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'Selected Comment Deleted Successfully',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

              

          }else{
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Error: Delete Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: "Unable to delete the selected",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error: Delete Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'Unable to delete the selected',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

          }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    addOrUpdateComment(objective_id,event,cmd_id = null){
      const cmd = event.target.value;
      if(cmd.length == 0){
        return;
      }
      axios
        .post("objective/addOrUpdateComment", {'comment' : cmd , 'objective_id' : objective_id , 'cmd_id' : cmd_id})
        .then(response => {
          if (response.data.success == true) {
            this.getMyOkRs();
            event.target.value = '' ;
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment Updated",
            //     text: response.data.message,
            //     icon: "BellIcon",
            //     variant: "primary",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Oops! Unable to fetch OKRs for Review",
            //     text: response.data.message,
            //     icon: "BellIcon",
            //     variant: "danger",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch OKRs for Review',
                icon: 'BellIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Unable to fetch OKRs for Review",
          //     text: error.message,
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch OKRs for Review',
                icon: 'BellIcon',
                variant: 'danger',
                text: error.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    markAsResolved(objective_id,comment,state = 'resolved'){
      axios.post("objective/resolved",{objective_id:objective_id,comment : comment,targetState:state})
        .then((res) => {
          if(res.data.success){
            this.getMyOkRs();
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment Addressed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: "Comment marked as resolved",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Addressed',
                icon: 'BellIcon',
                variant: 'success',
                text: "Comment marked as resolved",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

          }else{
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Error: Marking Failed",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: "Unable to mark the selected comment as rsolved",
            //   },
            // });
             
         this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error: Marking Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Unable to mark the selected comment as resolved",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

    },
    getDate(timestamp){
      const dateObj = new Date(timestamp);

      // Format date as DD/MM/YYYY
      const formattedDate = dateObj.toLocaleDateString("en-GB"); // "27/10/2024"
      return formattedDate;
    },
    getDateTime(timestamp){
      const dateObj = new Date(timestamp);

      // Format time as HH:MM AM/PM
      const formattedTime = dateObj.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
      return formattedTime;
    },
    closeHistory() {
      this.$bvModal.hide("edithistory-pop");
    },
    GetHistories(){
      var histories = [];
      for (let index = 0; index < this.nonPublishedOKRs.length; index++) {
        const objective = this.nonPublishedOKRs[index];
        if(objective.history.length){
          histories = histories.concat(objective.history);
        }        
        
      }
      return histories.reverse();
    },
    updateProgress(){
      // Circle Progress bar Design
      $(".circle_percent").each(function() {
        console.log($(this));
        var $this = $(this),
        $dataV = $this.attr('percent'),
        $dataDeg = $dataV * 3.6,
        $round = $this.find(".round_per");
        $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");	
        $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
        $this.prop('Counter', 0).animate({Counter: $dataV},
        {
          duration: 2000, 
          easing: 'swing', 
          step: function (now) {
                  $this.find(".percent_text").text(Math.ceil(now)+"%");
              }
          });
        if($dataV >= 51){
          $round.css("transform", "rotate(" + 360 + "deg)");
          setTimeout(function(){
            $this.addClass("percent_more");
          },1000);
          setTimeout(function(){
            $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
          },1000);
        } 
      });
    },
    // OKR COMMENTS DELETE
    confirmDeleteRecord(data) {
      this.$swal({
        text: `Deleting this comment can not be undone. Are you sure you want to continue?`,
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },
    // END OKR COMMENTS DELETE
    handleTabChange(oldIndex,nextIndex) {
      this.activeTab = 'step-'+(nextIndex+1);
      // You can perform actions based on the active tab ID change here
    },
    getLastQuarterTitle(){
      let type = this.$route.params.type;
      if (type == "individual-okr") {
        return this.userData.name + "'s " + this.getLastQuarterYear()
      }
      else if (type == "team-okr") {
        return "Team " + this.getLastQuarterYear();
      }
      else if (type == "organisation-okr") {
        return "Organisation " + this.getLastQuarterYear();
      } else {
        return this.userData.name + "'s " + this.getLastQuarterYear();
      }
    },
    getEmployees() {
      axios.get("/list/employees").then((res) => {
        this.employeeOptions = res.data.data;
      }).catch((error) => {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Oops! Unable to fetch Team Names",
        //     icon: "BellIcon",
        //     variant: "danger",
        //     text: error,
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch Team Names',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
      });
    },
    getStatusVariant(status){
      if(status == 'Upcoming'){
        return 'light-info';
      }else if(status == 'Not Started Yet'){
        return 'light-notyet';
      }else if(status == 'In Progress'){
        return 'light-warning';
      }else if(status == 'Completed'){
        return 'light-primary';
      }else if(status == 'Cancelled'){
        return 'light-danger';
      }else if(status == 'On Hold'){
        return 'light-dark';
      }else if(status == 'Deferred'){
        return 'light-dark';
      }
      return 'light-warning';
    },
    getTeamOptions() {
      this.isloading = true;
      axios.get("/list/divisions")
        .then((response) => {
          this.teamOptions = response.data.data;
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Unable to fetch Team Names",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch Team Names',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    formSubmitted() {
      let Ids = this.nonPublishedOKRs.map(item => item.hashid);
      if(Ids.length == 0){
        // this.$toast({
        //       component: ToastificationContent,
        //       props: {
        //         title: "OKR submission failed.",
        //         icon: "BellIcon",
        //         variant: "danger",
        //         text: 'There is no OKRs to Submit for approval',
        //       },
        //     });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR submission failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: 'There is no OKRs to Submit for approval',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        return;
      }
      if(this.type != 'Individual'){
        axios.post('objectives/publish',{
          objectiveIds : Ids,
          type : this.type,
        })
        .then((res) => {
          if (res.data.success == true) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKRs Published",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "All Objectives published successfully",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKRs Published',
                icon: 'BellIcon',
                variant: 'success',
                text: "All Objectives published successfully",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            this.nonPublishedOKRs=[];
            this.$router.push({ name: "my-okrs" });
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Failed to Publish OKRs ",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to Publish OKRs',
                icon: 'BellIcon',
                variant: 'danger',
                text:res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Failed to Publish OKRs ",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Failed to Publish OKRs `,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          console.error(err);
        });
      }else{
        axios.get('objectives/submit-review')
        .then((res) => {
          if (res.data.success == true) {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKRs submitted for review",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "All Objectives submitted for review",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKRs submitted for review',
                icon: 'BellIcon',
                variant: 'success',
                text: 'All Objectives submitted for review',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

            this.$router.push({ name: "my-okrs" });
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR submission failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR submission failed',
                icon: 'BellIcon',
                variant: 'danger',
                text:res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "OKR submission failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `OKR submission failed.`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })

          console.error(err);
        });
      }
      
    },
    submitChanged(newValue) {

      if(newValue == 'release'){
        this.is_loading.submit = false;
      }else{
        this.is_loading.submit = newValue;
      }
      if(newValue == false) {
        this.getMyOkRs();
      }
    },

    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.title}.`,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
          this.getMyOkRs();
        }
      });
    },
    deleteRecord(id) {
      axios.delete('objectives/' + id)
        .then((res) => {
          if (res.status == "204") {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "OKR Deleted Successfully.",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'OKR Deleted Successfully',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "OKR Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `OKR Deletion failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          console.error(err);
        });
    },
    updateObjectiveProgress(objective){
      axios.patch('objectives/'+objective.hashid, objective)
        .then((res) => {
          this.getMyOkRs();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "OKR Updated Successfully",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Updated Successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.getMyOkRs();
          // this.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! OKR Adding Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //       text: error,
          //     },
          //   });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! OKR Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
      let elementValue = objective.moveToNext;
      if(elementValue){
        this.AddtoCurrentOKR(objective);
      }
    },
    getCurrentQuarterYear() {
      const currentDate = new Date();
      const month = currentDate.getMonth() + 1; // JavaScript months are 0-indexed, so we add 1
      const quarter = Math.ceil(month / 3); // Calculate the quarter based on the month

      const year = currentDate.getFullYear(); // Get the current year
      
      // Create a string representing the current quarter and year
      const currentQuarterYear = `Q${quarter} ${year}`;
      return currentQuarterYear;
    },
    getLastQuarterYear() {
      const currentDate = new Date();
      const month = currentDate.getMonth() + 1; // JavaScript months are 0-indexed, so we add 1
      var quarter = Math.ceil(month / 3); // Calculate the quarter based on the month
      
      var year = currentDate.getFullYear(); // Get the current year

      if(quarter == 1){
        quarter = 4;
        year -= 1;
      }else{
        quarter -= 1;
      }
      
      // Create a string representing the current quarter and year
      const nextQuarterYear = `Q${quarter} ${year}`;
      return nextQuarterYear;
    },
    getNextQuarterYear() {
      const currentDate = new Date();
      const month = currentDate.getMonth() + 1; // JavaScript months are 0-indexed, so we add 1
      var quarter = Math.ceil(month / 3); // Calculate the quarter based on the month
      
      var year = currentDate.getFullYear(); // Get the current year

      if(quarter == 4){
        quarter = 1;
        year += 1;
      }else{
        quarter += 1;
      }
      
      // Create a string representing the current quarter and year
      const nextQuarterYear = `Q${quarter} ${year}`;
      return nextQuarterYear;
    },
    openTree(id) {
      var tree = 'KeyResultsView-' + id;
      this.$bvToggle(tree)
    },
    AddtoCurrentOKR(objective) {
      this.$refs.addForm.addObjective(objective)
    },
    getMyOkRs() {
      
      let type = this.$route.params.type;
      if (type == "individual-okr") {
        this.type = 'Individual';
      }
      else if (type == "team-okr") {
        this.type = 'Team';
      }
      else if (type == "organisation-okr") {
        this.type = 'Organisation';
      }
      axios.get(`objectives/myPendingOKRs?type=` + this.type)
      .then((res) => {
        if (res.data.success) {
          this.currentPublishedOKRs = res.data.data.currentPublishedOKRs;
          this.nonPublishedOKRs = res.data.data.nonPublishedOKRs;
          this.quarterType = res.data.data.quarterType;
          this.$nextTick(() => {
            this.updateProgress();
          });
        } else {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Unable to fetch OKRs",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch OKRs',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      }).catch((error) => {
        console.log(error);
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Oops! Unable to fetch OKRs",
        //     text: error,
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Unable to fetch OKRs',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
      });
      
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>